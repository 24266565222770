.login_register_switch {
	display: flex;
	align-items: center;
	border: 2px solid var(--color-brand);
	border-radius: 5rem;
	width: min-content;
	margin: 1rem auto;
	padding: 0.25rem;
}

.login_register_switch > a {
	display: block;
	padding: 0.75rem 1.2rem;
	border-radius: 5rem;
	text-decoration: none;
}

.login_register_switch > a.active {
	background-color: var(--color-brand);
	color: var(--color-text-reverse);
	font-weight: bold;
}
