.my_favourites:not(.no_favourites),
.my_checklists:not(.no_checklists) {
	display: grid;
	grid-template-columns: 100%;
	grid-auto-rows: 1fr;
	gap: 1rem;
}
@media (40rem < width <= 60rem), (width > 60rem) {
	.my_favourites:not(.no_favourites),
	.my_checklists:not(.no_checklists) {
		grid-template-columns: 1fr 1fr;
	}
}
.no_favourites,
.no_checklists {
	text-align: center;
	padding: 3rem var(--main_padding);
}
.no_favourites > svg,
.no_checklists > svg {
	color: var(--color-brand);
	--icon_size: 2rem;
	width: var(--icon_size);
	height: var(--icon_size);
}
.no_favourites .no_favourites_message,
.no_checklists .no_checklists_message {
	color: var(--color-text-body);
	margin: 0.5rem 0;
	font-size: 1.5rem;
}
.no_favourites .button,
.no_checklists .button {
	font-size: 1.25rem;
	font-weight: 500;
	border-radius: 5rem;
	margin: 1rem;
}
