.docs_nav {
	color: var(--color-text);
	background: var(--color-background);
	scrollbar-width: none;
}
.docs_nav::-webkit-scrollbar {
	display: none;
}
.docs_nav > ul {
	list-style: none;
	margin: 0;
}
.docs_nav_item {
	text-decoration: none;
	border: none;
	background: none;
	color: inherit;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 0.5rem;
}
.docs_nav_item.has_children svg {
	width: 1rem;
	pointer-events: none;
	transition: transform var(--micro-animation);
}
.docs_nav_submenu {
	list-style: none;
}
.docs_nav_subitem {
	padding: 0.5rem;
	text-decoration: none;
	border-radius: 0.5rem;
	display: grid;
	grid-template-columns: 2rem 1fr;
	gap: 0.5rem;
	color: inherit;
}
.docs_nav_subitem.current_sub_section {
	background-color: var(--color-brand-faded-background);
	color: var(--color-brand);
	font-weight: 700;
}
.content_header .subsection_number {
	font-size: 1rem;
	font-weight: 700;
	line-height: 1.75;
	margin: 0;
	color: var(--color-brand);
}
.subsection_title {
	font-size: 1.5rem;
}
.subsection_date {
	font-size: 0.875rem;
	line-height: 1.5;
	color: var(--color-text-faded);
	margin: 0;
}
.gallery .images {
	--gap: 0.5rem;
	gap: var(--gap);
}
.gallery .images > a {
	aspect-ratio: 1;
}
.gallery .images > a > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.docs_searchbar {
	background-color: var(--color-background-alt);
	border-right: 1px solid var(--color-border);
	border-bottom: 1px solid var(--color-border);
	display: flex;
	align-items: center;
	justify-content: center;
}
.docs_search_open_popup {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	border: 1px solid var(--color-border);
	border-radius: 5rem;
	background-color: var(--color-background);
	padding: 0.25rem 0.5rem;
	cursor: pointer;
}
.docs_search_open_popup > svg {
	flex: 0 0 1rem;
}
@media ((hover: none) and (pointer: coarse)) {
	.docs_search_open_popup > svg ~ svg {
		visibility: hidden;
	}
	.search_keyboard_shortcuts {
		display: none;
	}
}
.docs_search_popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: var(--color-overlay-background);
	z-index: 15;
}
.docs_search_popup:not(.show) {
	display: none;
}
.docs_search_popup_content {
	--padding: 1rem;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	margin: 5vh 0;
	max-height: 90vh;
	max-width: 100vw;
	background-color: var(--color-background);
	border-radius: 0.5rem;
	overflow: hidden;
	width: 37.5rem;
}
.docs_search_form {
	padding: var(--padding);
	display: flex;
	align-items: center;
	border-bottom: 1px solid var(--color-border);
}
.docs_search_form > svg {
	flex: 0 0 2rem;
	width: 2rem;
	padding: 0.25rem;
}
.docs_search_input {
	flex: 1 1 auto;
	border: none;
	background-color: var(--color-background);
	margin: 0 0.5rem;
	font-size: 1.5rem;
	width: 100%;
}
.docs_search_results {
	padding: var(--padding);
	border-bottom: 1px solid var(--color-border);
}
.docs_search_results:not(.show) {
	display: none;
}
.docs_search_result {
	display: block;
	padding: 1rem;
	border-radius: 0.5rem;
}
.docs_search_result:hover {
	background-color: var(--color-background-alt);
}
.docs_search_result.selected {
	background-color: var(--color-brand);
	color: var(--color-text-reverse);
}
.docs_no_search_result {
	margin: 0;
}
.search_keyboard_shortcuts {
	display: flex;
	gap: 1rem;
	background-color: var(--color-background-alt);
	padding: var(--padding);
}
.search_shortcut {
	display: flex;
	align-items: center;
	gap: 1rem;
}
.search_shortcut > svg {
	flex: 0 0 1rem;
	width: 1rem;
}
.docs_content {
	background: var(--color-background);
}
.docs_subsection_content {
	scroll-margin: var(--scroll-margin);
}
:not(.wp-block-custom.case_study) > .wp-block-custom.accordion {
	background: var(--color-brand-faded-background);
}
@media not print {
	.qrcode {
		display: none;
	}
}
@media ((hover: none) and (pointer: coarse)) {
	.docs_search_open_popup > svg ~ svg {
		visibility: hidden;
	}
	.search_keyboard_shortcuts {
		display: none;
	}
}
@media (width <= 40rem) {
	.docs_content {
		padding: var(--main_padding);
	}
	.documentation {
		position: relative;
		--scroll-margin: calc(var(--header_height) + var(--docs_nav_height) + var(--main_padding) - 1px);
	}
	.docs_nav {
		background-color: var(--color-background-alt);
		position: sticky;
		top: var(--header_height);
		z-index: 8;
		height: var(--docs_nav_height);
	}
	.docs_nav > ul {
		display: flex;
		gap: 1rem;
		overflow-x: auto;
		background-color: var(--color-background-alt);
		padding: 0.5rem 1.2rem;
	}

	.docs_nav_item {
		padding: 0.5rem 0.75rem;
		white-space: nowrap;
		border-radius: 5rem;
		font-size: 0.875rem;
	}

	.docs_nav_item.has_children svg {
		transform: rotate(0deg);
	}

	li.open > .docs_nav_item.has_children svg {
		transform: rotate(180deg);
	}

	.docs_nav_item.current_section {
		background-color: var(--color-brand);
		color: var(--color-text-reverse);
		font-weight: 700;
	}

	li:not(.open) > .docs_nav_submenu {
		display: none;
	}

	.docs_nav_submenu {
		background-color: var(--color-background);
		position: absolute;
		left: 0;
		top: 100%;
		width: 85%;
		border-radius: 0 0 0.5rem 0;
		padding: 0.5rem;
		max-height: calc(100vh - (var(--header_height) + var(--docs_nav_height) + 1rem)) !important;
		overflow: auto;
	}
	li.open > .docs_nav_overlay {
		background-color: var(--color-overlay-background);
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
	}
	.docs_searchbar {
		position: sticky;
		width: 100%;
		border-bottom: 6px solid var(--color-brand);
		padding: 1rem;
		top: calc(var(--header_height) - 0.9rem);
		z-index: 5;
		transition: top var(--micro-animation);
		border-top: 1px solid var(--color-border);
	}
	.docs_searchbar.show {
		top: calc(var(--header_height) + var(--docs_nav_height));
	}
	.docs_searchbar .docs_search_open_popup {
		width: 100%;
	}
	.show_searchbar_button {
		position: absolute;
		color: var(--color-text-reverse);
		background-color: var(--color-brand);
		width: 3.5rem;
		height: 2rem;
		top: 100%;
		border: 0;
		border-bottom-left-radius: 10rem;
		border-bottom-right-radius: 10rem;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: -1;
	}
	.show_searchbar_button svg {
		height: 0.75rem;
		transition: transform var(--micro-animation);
	}
	.docs_searchbar.show .show_searchbar_button svg {
		transform: rotate(180deg);
		transform-origin: center;
	}
}
@media (width <= 40rem), (40rem < width <= 60rem) {
	.gallery header {
		display: none;
	}
	.gallery .images {
		display: flex;
		overflow-x: auto;
	}
	.gallery .images > a {
		flex: 0 0 calc(25% - ((var(--gap) * 3) / 4));
	}
	.gallery .images > a.more_images {
		display: none;
	}
}
@media (40rem < width <= 60rem), (width > 60rem) {
	.documentation {
		display: grid;
		grid-template-columns: 16rem 1fr;
		grid-template-rows: var(--docs_section_title_height) auto;
		grid-template-areas: "searchbar content" "docs_nav content";
		--docs_sidebar_gap: 1.5rem;
		--scroll-margin: calc(var(--header_height) + var(--docs_section_title_height) + var(--main_padding) - 1px);
	}
	.docs_searchbar {
		grid-area: searchbar;
		padding: 0.75rem;
		position: sticky;
		top: var(--header_height);
		border-top: 1px solid var(--color-border);
		z-index: 1;
	}
	.show_searchbar_button {
		display: none;
	}
	.docs_nav {
		grid-area: docs_nav;
		border-right: 1px solid var(--color-border);
		max-height: calc(100vh - var(--header_height) - var(--docs_section_title_height));
		position: sticky;
		top: calc(var(--header_height) + var(--docs_section_title_height));
		overflow: auto;
	}
	.docs_nav > ul {
		padding: 0.5rem 0.75rem;
	}
	.docs_nav > ul > li:not(:last-child) {
		border-bottom: 1px solid var(--color-border);
	}
	.docs_content {
		grid-area: content;
		display: grid;
		grid-template-columns: 100%;
		grid-template-areas: "section_title" "section_content";
		padding: 0;
	}
	.docs_content .content > *:first-child {
		margin-top: 0;
	}
	.docs_content .content > *:last-child {
		margin-bottom: 0;
	}
	.docs_section_title {
		grid-area: section_title;
		background-color: var(--color-background-alt);
		border-bottom: 1px solid var(--color-border);
		border-top: 1px solid var(--color-border);
		padding: 0.75rem 1rem;
		display: flex;
		align-items: center;
		height: var(--docs_section_title_height);
		position: sticky;
		top: var(--header_height);
		z-index: 1;
	}
	.docs_section_title h2 {
		color: var(--color-brand);
		margin: 0;
	}
	.docs_section_content {
		grid-area: section_content;
		display: grid;
		padding: var(--main_padding);
	}
	.docs_subsection_content {
		display: grid;
		height: min-content;
		gap: 0 var(--docs_sidebar_gap);
	}
	.docs_subsection_content > :last-child {
		margin-bottom: 0;
	}
	.docs_subsection_content:not(:first-child) {
		margin-top: 1.5rem;
	}
	.docs_nav_item {
		padding: 0.5rem;
		border-radius: 0.5rem;
		width: 100%;
		font-size: 1rem;
		font-weight: 700;
	}
	.docs_nav_item.has_children {
		cursor: pointer;
	}
	.docs_nav_item.has_children svg {
		transform: rotate(-90deg);
	}
	li.open > .docs_nav_item.has_children svg,
	.docs_nav_item.current_section.has_children svg {
		transform: rotate(0deg);
	}
	li:not(.open) > .docs_nav_item:not(.current_section) ~ .docs_nav_submenu {
		max-height: 0 !important;
	}
	.docs_nav_submenu {
		padding: 0;
		transition: max-height var(--micro-animation);
		max-height: 100vh;
		overflow: hidden;
	}
	.docs_nav_submenu li:first-child {
		margin-top: 0.5rem;
	}
	.docs_nav_submenu li:last-child {
		margin-bottom: 0.5rem;
	}
	.docs_nav_overlay {
		display: none;
	}
	.content_header {
		grid-area: header;
	}
	.docs_subsection_content > hr.docs_separator:first-of-type {
		grid-area: separator;
	}
	.content_sidebar {
		grid-area: content_sidebar;
	}
	.content {
		grid-area: content;
	}
	.section_actions {
		grid-area: section_actions;
	}
	.content_header,
	hr.docs_separator,
	.content,
	.section_actions,
	.docs_section_title h2 {
		width: 100%;
		max-width: 50rem;
		margin-left: auto;
		margin-right: auto;
	}
}
@media (40rem < width <= 60rem) {
	.docs_subsection_content {
		grid-template-columns: 100%;
		grid-template-areas:
			"header"
			"separator"
			"content_sidebar"
			"content"
			"separator-2"
			"section_actions"
			"separator-3";
	}
}
@media (width > 60rem) {
	.docs_subsection_content {
		grid-template-columns: 1fr 20rem;
		grid-template-areas:
			"header content_sidebar"
			"separator content_sidebar"
			"content content_sidebar"
			"separator-2 content_sidebar"
			"section_actions content_sidebar"
			"separator-3 content_sidebar";
	}
	.content_sidebar > hr.docs_separator {
		display: none;
	}
	.docs_section_title {
		padding-right: calc(var(--main_padding) + 20rem + var(--docs_sidebar_gap));
	}
	.gallery {
		padding: 1rem;
		border-radius: 0.5rem;
		border: 1px solid var(--color-border);
		position: sticky;
		top: calc(var(--header_height) + var(--docs_section_title_height) + var(--main_padding));
	}
	.gallery header h4,
	.gallery header p {
		margin: 0 0 0.5rem;
	}
	.gallery header p {
		font-style: italic;
	}
	.gallery .images {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
	}
	.gallery .images > a.more_images {
		display: block;
		background-color: var(--color-overlay-background);
		color: var(--color-text-reverse);
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0.5rem;
		text-decoration: none;
	}
	.gallery .images > a:nth-child(n + 10) {
		display: none;
	}
}

.section_actions {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	gap: 1rem;
	padding: 1rem 0;
}
.section_rating {
	display: flex;
	align-items: center;
	gap: 0.5rem 2rem;
	flex-wrap: wrap;
}
.section_rating_label {
	margin: 0;
	font-weight: bold;
}
.section_rating_button {
	padding: 0.75rem 1.2rem;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	background-color: var(--color-background);
	border: 1px solid var(--color-brand);
	border-radius: 0.5rem;
	color: var(--color-brand);
	text-decoration: underline;
	line-height: 1;
	justify-content: center;
	flex: 1 1 7.5rem;
	cursor: pointer;
	max-width: 7.5rem;
}
.section_rating_button svg {
	--icon_size: 1rem;
	flex: 0 0 var(--icon_size);
	height: var(--icon_size);
}
.section_rating_wrapper {
	flex: 9999 0 auto;
	max-width: 100%;
}
.section_quick_actions {
	display: flex;
	gap: 1rem;
	justify-content: center;
	flex: 1 0 auto;
	flex-wrap: wrap;
}
.section_rating_buttons {
	display: flex;
	align-items: center;
	gap: 1rem;
	flex: 1;
}
.section_quick_action_button {
	display: flex;
	align-items: center;
	border: 1px solid var(--color-brand);
	border-radius: 5rem;
	background: var(--color-background);
	color: var(--color-brand);
	padding: 0.5rem;
	cursor: pointer;
}
.section_quick_action_button.submitting {
	animation: wobble-border 0.5s infinite alternate ease-in-out;
	outline: 0 solid var(--color-brand);
	outline-offset: -1px;
}
.section_quick_action_button svg {
	width: 1.5rem;
	height: 1.5rem;
}
.section_rating_button,
.section_quick_action_button {
	transition: background-color var(--micro-animation), color var(--micro-animation);
}
.section_rating_button.checked,
.section_quick_action_button.checked {
	background-color: var(--color-brand);
	color: var(--color-text-reverse);
}
@media (hover: hover) {
	.section_rating_button:hover,
	.section_quick_action_button:hover {
		background-color: var(--color-brand);
		color: var(--color-text-reverse);
	}
}

@keyframes wobble-border {
	50% {
		outline-width: 3px;
	}
}

@media screen {
	.footer_text {
		display: none;
	}
}



