.checklist_single_header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 1rem;
	flex-wrap: wrap;
	margin: 0 var(--main_padding) var(--main_padding);
	padding-top: var(--main_padding);
}
.checklist_info .checklist_title {
	font-size: 1.75rem;
	font-weight: bold;
	color: var(--color-brand);
	margin: 0.5rem 0;
}
.checklist_info .last_updated {
	font-size: 0.875rem;
	margin: 0.5rem 0;
}
.checklist_actions {
	display: flex;
	gap: 1rem;
	align-items: stretch;
	flex-wrap: wrap;
}
.iframe_container {
	resize: both;
	overflow: auto;
	line-height: 0;
	max-width: calc(100% - var(--main_padding) * 2);
	margin: var(--main_padding) auto 2rem;
}
.iframe_container::before {
	display: block;
	content: "";
}
.iframe_container.landscape {
	width: 75rem;
	position: relative;
}
.iframe_container.landscape::before {
	padding-top: 70%;
}
.iframe_container.portrait {
	width: 52.5rem;
}
.iframe_container.portrait::before {
	padding-top: 140%;
}

.iframe_container iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.PDF_fallback_message {
	position: absolute;
	top: 0;
	left: 0;
	line-height: 1.5;
	height: 0;
	margin: 0;
}
