.checklists_content {
	display: grid;
	grid-template-columns: 100%;
	grid-auto-rows: 1fr;
	margin-bottom: 2rem;
	margin: -0.5rem auto;
}
.checklists_content.site_container.padding {
	padding-left: 0.75rem;
	padding-right: 0.75rem;
}
.checklists_content .checklist {
	margin: 0.5rem;
}
@media (40rem < width <= 60rem), (width > 60rem) {
	.checklists_content {
		grid-template-columns: 1fr 1fr;
	}
}
