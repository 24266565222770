.common_page_header {
	--container-width: var(--site-container-width);
	max-width: var(--container-width);
	margin-left: auto;
	margin-right: auto;
	padding-left: var(--main_padding);
	padding-right: var(--main_padding);
	padding-top: var(--main_padding);
}
.common_page_header.has_search {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 1rem;
}
.common_page_header.has_search .page_title {
	flex: 999 0 auto;
}
.common_page_header.has_search form {
	flex: 1 0 auto;
}
.common_page_header .page_title {
	font-weight: bold;
	font-size: clamp(1.5rem, 1vw + 1rem, 1.75rem);
	line-height: 1.2;
	color: var(--color-brand);
}
@media (width <= 40rem) {
	.common_page_header .page_title {
		text-align: center;
	}
	.common_page_header.has_search .page_title {
		flex-basis: 100%;
	}
}
