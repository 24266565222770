.register_form {
	margin: 0 auto 3rem;
	width: 100%;
	max-width: 45rem;
	background-color: var(--color-background);
	border-radius: clamp(0rem, ((100vw - 0.25rem) - 100%) * 9999, 0.5rem);
	border: 1px solid var(--color-border);
	grid-template-columns: 1fr;
	gap: 1.5rem;
}
.register_form > h2 {
	margin: 0;
	color: var(--color-brand);
}
.register_form > hr {
	margin: 0;
}
.data_privacy_checkbox_group_wrapper .field_label,
.newsletter_signup_checkbox_group_wrapper .field_label,
.terms_checkbox_group_wrapper .field_label {
	font-weight: bold;
	margin-top: 25px;
}
.data_privacy_checkbox_group_wrapper .field_label::after,
.newsletter_signup_checkbox_group_wrapper .field_label::after,
.terms_checkbox_group_wrapper .field_label::after {
	font-weight: normal;
}
.register_submit {
	width: 10rem;
	margin-left: auto;
	align-self: flex-end;
	display: flex;
	justify-content: center;
	margin-top: 25px;
}
@media (width <= 40rem) {
	.register_form {
		padding: 1.5rem;
	}
}
@media (40rem < width <= 60rem), (width > 60rem) {
	.register_form {
		padding: 2.5rem;
	}
}
