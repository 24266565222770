.contact_page {
	max-width: 60rem;
	margin-left: auto;
	margin-right: auto;
	padding: var(--main_padding) 0;
}
.contact_page .common_page_header {
	padding: 0 var(--main_padding) var(--main_padding);
	max-width: 60rem;
}
.contact_page_content {
	display: flex;
	flex-wrap: wrap-reverse;
	gap: 2rem 0;
}
.contact_info_sidebar {
	flex: 1 0 15rem;
	padding: 0 var(--main_padding);
}
.contact_info_sidebar address {
	font-style: normal;
}
.contact_info_sidebar .company_name {
	color: var(--color-brand);
	font-size: 1.25rem;
	font-weight: bold;
	margin: 0;
}
.contact_info_sidebar .prefix {
	font-weight: bold;
	display: block;
	color: var(--color-brand);
}
.contact_info_sidebar a {
	color: var(--color-text-body);
}
.contact_form {
	flex: 9999 1 38rem;
	display: grid;
	grid-template-columns: 1fr;
	gap: 1rem;
	padding: var(--main_padding);
	background-color: var(--color-background);
	border-radius: clamp(0rem, ((100vw - 0.25rem) - 100%) * 9999, 0.5rem);
	border: 1px solid var(--color-border);
}
.contact_form hr {
	margin: 0;
}
.contact_submit {
	margin-left: auto;
}
@media (40rem < width <= 60rem), (width > 60rem) {
	.contact_page {
		padding-bottom: 4rem;
	}
}
