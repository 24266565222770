/* Field wrappers */
.field_wrapper:first-child {
	margin-top: 0;
}
.field_wrapper:last-child {
	margin-bottom: 0;
}
.label_wrapper {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom: 0.5rem;
}
.field_label {
	margin: 0;
	flex: 0 0 auto;
}
.validation_error {
	color: red;
	font-size: 0.875rem;
	margin: 0;
	text-align: right;
	align-self: flex-end;
	flex: 1 1 auto;
}
.required_field .field_label::after {
	content: " *";
	color: red;
}
:not(.required_field):not(.no_label_suffix) > .label_wrapper .field_label::after {
	content: " (optional)";
}
.input_wrapper {
	position: relative;
	--input_icon_size: 1.5rem;
}
@media (width <= 40rem) {
	.input_wrapper {
		--input_icon_padding: 0.75rem;
	}
}
@media (40rem < width <= 60rem), (width > 60rem) {
	.input_wrapper {
		--input_icon_padding: 1rem;
	}
}
.input_wrapper > .leading_icon,
.input_wrapper > .trailing_icon {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: var(--input_icon_size);
	height: var(--input_icon_size);
	line-height: 0;
	color: var(--color-text-body);
}
.input_wrapper > .leading_icon svg,
.input_wrapper > .trailing_icon svg {
	width: 100%;
	height: 100%;
}
.input_wrapper > .leading_icon {
	left: var(--input_icon_padding);
}
.input_wrapper > .trailing_icon {
	right: var(--input_icon_padding);
}
.field_wrapper.has_leading_icon input {
	padding-left: calc(var(--input_icon_size) + (var(--input_icon_padding) * 2));
}
.field_wrapper.has_trailing_icon input {
	padding-right: calc(var(--input_icon_size) + (var(--input_icon_padding) * 2));
}

/* Single and multi line inputs */
.single_line_input,
.multi_line_input {
	width: 100%;
	border-radius: 0.5rem;
	border: 1px solid var(--color-border);
	line-height: 1.5;
	scroll-margin: var(--scroll-margin);
}
@media (width <= 40rem) {
	.single_line_input,
	.multi_line_input {
		padding: 0.75rem;
	}
}
@media (40rem < width <= 60rem), (width > 60rem) {
	.single_line_input,
	.multi_line_input {
		padding: 1.125rem;
	}
}

/* Checkbox + Checkbox group */
.checkbox_wrapper {
	position: relative;
	--checkbox_size: 1.5rem;
	display: flex;
	align-items: baseline;
}
.checkbox {
	position: absolute;
	top: 0;
	opacity: 0;
	width: var(--checkbox_size);
	height: var(--checkbox_size);
	margin: 0;
	scroll-margin: var(--scroll-margin);
}
.checkbox + svg {
	width: var(--checkbox_size);
	height: var(--checkbox_size);
	position: absolute;
	top: 0;
}
.checkbox:focus:not(:focus-visible) + svg {
	outline: none;
}
.checkbox:focus-visible:focus-visible + svg {
	outline: 2px solid var(--color-brand);
	outline-offset: 2px;
}

.checkbox:checked + svg .checkbox__bg {
	fill: var(--color-brand);
	stroke: var(--color-text);
}
.checkbox:checked + svg .checkbox__checkmark {
	stroke: var(--color-text-reverse);
}
.checkbox_wrapper span {
	margin-left: calc(var(--checkbox_size) + 0.5rem);
}

/* Password input */
.password_strength_information meter {
	width: 100%;
}
.password_strength_information .suggestion_list {
	margin: 0;
}

/* Select field */
.select_input_container {
	display: flex;
	align-items: center;
	border-radius: 0.5rem;
}
.select_real_input {
	opacity: 0;
	position: absolute;
	z-index: -1;
	scroll-margin: var(--scroll-margin);
}
.select_false_input {
	pointer-events: none;
}
.select_dropdown_arrow {
	--input_icon_size: 1rem;
	pointer-events: none;
}
.select_options_group {
	position: absolute;
	top: 100%;
	width: 100%;
	background-color: var(--color-background);
	color: var(--color-text);
	border: 1px solid var(--color-border);
	border-radius: 0.5rem;
	padding: 0.25rem;
	z-index: 5;
}
.select_option {
	cursor: default;
	padding: 0.25rem;
	border-radius: 0.25rem;
}
.select_option.selected,
.select_option:focus-visible {
	background-color: var(--color-brand);
	color: var(--color-text-reverse);
}
@media (hover: hover) {
	.select_option:hover {
		background-color: var(--color-brand);
		color: var(--color-text-reverse);
	}
}
.select_options_group:not(.show):not(:focus-within) {
	display: none;
}
.select_options_backdrop {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 4;
}
.select_options_backdrop:not(.show) {
	display: none;
}
/* Search */
.search_input {
	width: 100%;
	border-radius: 5rem;
	border: 1px solid var(--color-border);
	padding: 0.25rem 0.25rem 0.25rem 1.75rem;
}
.search_input::placeholder {
	font-size: 0.9rem;
}
.search_input_wrapper .input_wrapper {
	--input_icon_size: 1rem;
	--input_icon_padding: 0.5rem;
}
.search_input_wrapper .input_wrapper > .leading_icon,
.search_input_wrapper .input_wrapper > .trailing_icon {
	color: var(--color-text-faded);
}
/* Assume physical keyboard if mouse or trackpad is primary input. */
@media ((hover: none) and (pointer: coarse)) {
	.search_input + .trailing_icon {
		display: none;
	}
}
.select_fallback_input {
	appearance: none;
}

/* Validation error message */
.validation_errors {
	border: 1px solid var(--color-error);
	color: var(--color-error);
	border-radius: 0.5rem;
	padding: 0.5rem 1rem;
	margin: 25px 0 0;
}

/* Honeypot */
.honey_im_home {
	display: none;
}
