.site_header {
	background-color: var(--color-background-reverse);
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	z-index: 10;
	position: sticky;
	top: 0;
	height: var(--header_height);
}

.logo {
	position: relative;
	z-index: 11;
}

.logo a {
	display: flex;
	align-items: center;
}

.logo svg {
	width: 100%;
}

.main_nav {
	position: relative;
}
.main_menu .nav_link {
	color: var(--color-text-reverse);
	text-decoration: none;
}
.main_menu .nav_link > svg {
	width: 1rem;
}
button.nav_link {
	background: none;
	border: none;
	text-align: left;
	cursor: pointer;
}
.main_menu .nav_link.active {
	font-weight: bold;
	text-decoration: underline;
	text-decoration-thickness: 2px;
	text-underline-offset: 1px;
}
nav.main_menu {
	display: flex;
}
.nav_submenu {
	list-style: none;
}
.nav_submenu:not(.open) {
	display: none;
}
.nav_submenu a {
	display: block;
}
@media (width <= 40rem), (40rem < width <= 60rem) {
	.site_header {
		padding: 1rem 1.25rem;
	}
	.logo {
		width: 10rem;
	}
	.menu_toggle {
		position: absolute;
		right: 0;
		z-index: 12;
		margin: 0;
		width: 100%;
		height: 100%;
		appearance: unset;
		visibility: hidden;
		background: transparent;
	}

	.menu_icon {
		--icon-width: 1.75rem;
		--icon-line-height: 3px;
		--icon-line-spacing: 5px;
		width: var(--icon-width);
		height: calc((var(--icon-line-height) * 3) + (var(--icon-line-spacing) * 2));
		position: relative;
		cursor: pointer;
		z-index: 11;
	}

	.menu_icon span {
		display: block;
		position: absolute;
		height: var(--icon-line-height);
		width: 100%;
		background: var(--color-text-reverse);
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: all var(--micro-animation);
	}

	.menu_icon span:nth-child(1) {
		top: 0;
	}

	.menu_icon span:nth-child(2),
	.menu_icon span:nth-child(3) {
		top: calc(var(--icon-line-height) + var(--icon-line-spacing));
	}

	.menu_icon span:nth-child(4) {
		top: calc((var(--icon-line-height) + var(--icon-line-spacing)) * 2);
	}

	.menu_toggle:checked ~ .menu_icon span:nth-child(2) {
		transform: rotate(45deg);
	}

	.menu_toggle:checked ~ .menu_icon span:nth-child(3) {
		transform: rotate(-45deg);
	}

	.menu_toggle:checked ~ .menu_icon span:nth-child(1),
	.menu_toggle:checked ~ .menu_icon span:nth-child(4) {
		width: 0;
		top: 50%;
		left: 50%;
	}

	nav.main_menu {
		display: flex;
		position: fixed;
		top: var(--header_height);
		left: 0;
		width: 100%;
		height: auto;
		max-height: calc(100vh - var(--header_height));
		overflow: auto;
		background: var(--color-background-reverse);
		z-index: 10;
		flex-direction: column;
	}
	.main_menu_overlay {
		height: calc(100vh - var(--header_height));
		width: 100vw;
		z-index: 9;
		position: fixed;
		top: var(--header_height);
		left: 0;
		background-color: var(--color-overlay-background);
	}
	.menu_toggle:not(:checked) ~ nav.main_menu {
		display: none;
	}
	.menu_toggle:not(:checked) ~ .main_menu_overlay {
		display: none;
	}
	.main_menu .nav_link {
		--link_padding: 1rem;
		padding: var(--link_padding);
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-top: 1px solid #4f4f4f;
		background: var(--color-background-reverse);
	}
	.main_menu .nav_link.my_account_link .button {
		width: 100%;
	}
	.main_menu .nav_link.has_children.submenu_open {
		background-color: #4f4f4f;
		flex-wrap: wrap;
		padding-bottom: 0.25rem;
	}
	.main_menu .nav_link.has_children.submenu_open > svg {
		transform: rotate(90deg);
	}
	.nav_submenu {
		margin: var(--link_padding) calc(var(--link_padding) * -1) 0;
		flex: 1 0 100%;
		padding: 0;
	}
}
@media (width > 60rem) {
	.site_header {
		padding: 1rem 1.25rem;
	}
	.logo {
		width: 13.75rem;
	}
	.menu_toggle {
		display: none;
	}
	nav.main_menu {
		display: flex;
		align-items: center;
		margin: 0 -1rem;
	}
	.main_menu .nav_link {
		font-size: 1rem;
		margin: 0 1rem;
	}
	.main_menu .nav_link.has_children {
		padding-right: 1.5rem;
	}
	.nav_submenu {
		position: fixed;
		top: 3rem;
		background: var(--color-background-reverse);
		padding: 1rem;
		margin: 0;
	}
	.nav_submenu li {
		margin: 0.5rem 0;
	}
	.nav_submenu li:first-child {
		margin-top: 0;
	}
	.nav_submenu li:last-child {
		margin-bottom: 0;
	}
	.main_menu .nav_link:not(.has_children) > svg {
		display: none;
	}
	.main_menu .nav_link.has_children {
		position: relative;
	}
	.main_menu .nav_link.has_children > svg {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%) rotate(90deg);
	}
	.main_menu .nav_link.has_children.submenu_open > svg {
		transform: translateY(-50%) rotate(270deg);
	}
	.main_menu .nav_link.my_account_link .button {
		text-align: center;
	}
	.main_menu .nav_link.my_account_link .button > svg {
		display: none;
	}
}
