.social_links {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 2.5rem;
}
.social_links > a {
	width: 3rem;
	height: 3rem;
	display: block;
	background: var(--color-background);
	border: 1px solid var(--color-border);
	border-radius: 50%;
	margin: 0 0.5rem;
	padding: 0.5rem;
}
.social_links > a > svg {
	width: 100%;
	height: 100%;
	object-fit: contain;
	fill: var(--color-background-reverse);
}
.social_links > a:active {
	background: var(--color-brand);
}
.social_links > a:active > svg {
	fill: var(--color-background);
}
