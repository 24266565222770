.checklist {
	display: flex;
	align-items: center;
	gap: 1rem;
	border: 1px solid var(--color-border);
	border-radius: 0.5rem;
	padding: 1rem;
	background: var(--color-background);
	text-decoration: none;
}
.checklist_icon {
	--icon_size: clamp(3rem, 5vw + 1rem, 4rem);
	margin: 0;
	width: var(--icon_size);
	height: var(--icon_size);
	flex: 0 0 var(--icon_size);
}
.checklist_icon img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}
.checklist_title {
	color: var(--color-text-body);
	margin: 0;
	font-size: clamp(1.25rem, 1vw + 1rem, 1.5rem);
	font-weight: 500;
}
