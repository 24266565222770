.favourited_section {
	border: 1px solid var(--color-border);
	border-radius: 0.5rem;
	display: grid;
	grid-template-columns: auto 7.5rem;
	grid-template-areas: "section_title section_title" "post_title post_title" "last_updated remove";
	grid-auto-rows: 0.1fr 1fr 0.1fr;
	gap: 1rem;
	padding: 1rem;
}
.favourited_section.hidden {
	display: none;
}
.favourited_section .section_title {
	color: var(--color-brand);
	grid-area: section_title;
	margin: 0;
	font-weight: bold;
}
.favourited_section .post_title {
	grid-area: post_title;
}
.favourited_section .post_title h2 {
	font-size: 1.5rem;
	margin: 0;
}
.favourited_section .last_updated {
	font-size: 0.875rem;
	color: var(--color-text-faded);
	grid-area: last_updated;
	margin: 0;
}
.favourited_section .remove_from_favourites_form {
	grid-area: remove;
}
.favourited_section .remove_from_favourites {
	background: none;
	border: none;
	color: var(--color-error);
	display: flex;
	align-items: center;
	gap: 0.5rem;
	height: 100%;
	cursor: pointer;
	margin-left: auto;
}
.favourited_section .remove_from_favourites > svg {
	--icon_size: 1.5rem;
	width: var(--icon_size);
	height: var(--icon_size);
}
