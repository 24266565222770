.password_content {
	margin: auto;
	width: 100%;
	max-width: 50rem;
	background-color: var(--color-background);
	border-radius: clamp(0rem, ((100vw - 0.25rem) - 100%) * 9999, 0.5rem);
	border: 1px solid var(--color-border);
	display: block;
	grid-template-columns: 1fr 1fr;
	grid-template-areas:
		"username username"
		"password password"
		"forgot_pass forgot_pass"
		"remember_me submit";
	align-items: center;
	gap: 1.5rem;
}


.password_submit {
	margin-left: auto;
	grid-area: submit;
}

.password_wrapper {
	margin-bottom: 0px;
}
