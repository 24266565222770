.about_page {
	--container-width: 75rem;
}
.icon_section {
	position: relative;
	padding: 0.1px;
	background-color: var(--section_background);
}
.icon_section > * {
	max-width: var(--container-width);
	margin-inline: auto;
}

.icon_section:first-of-type {
	--section_background: #E5DF1D;
	color: var(--color-text-body);
}

.icon_section:nth-of-type(3) {
	--section_background: #C14B97;
	color: var(--color-text-reverse);
}

.icon_section:last-of-type {
	--section_background: #5FC297;
	color: var(--color-text-reverse);
}

.icon_section:nth-child(even) {
	--section_background: var(--color-background-alt);
}

.icon_section h2 {
	font-size: 2rem;
}
.icon_section:nth-child(odd) h2 {
	color: var(--color-text-reverse);
}
.icon_section:first-of-type h2 {
	color: var(--color-text-body);
}
.icon_section p {
	font-size: 1.25rem;
}
.icon_section .wp-block-media-text__media {
	padding: 2rem;
}
.icon_section .wp-block-media-text__media img {
	position: relative;
}

.quote_section {
	background-color: var(--color-brand-dark);
	display: flex;
	flex-direction: column;
	place-content: center;
	padding: 2rem;
	gap: 1rem;
}
.quote_section > * {
	color: var(--color-text-reverse);
	max-width: 50rem;
	margin: 0;
}
.quote_logo {
	max-width: 20rem;
	width: 80%;
	margin-inline: auto;
}
.the_quote,
.author_name,
.author_job_title {
	font-size: 1.25rem;
}
.the_quote::before,
.the_quote::after {
	content: '"';
}
.author_name,
.author_job_title {
	margin: 0;
}
.author_name {
	font-weight: bold;
}
@media (40rem < width <= 60rem), (width > 60rem) {
	.quote_section {
		height: 37.5rem;
	}

	.quote_section > * {
		text-align: center;
		margin-inline: auto;
	}
}
