.gallery_pop_up {
	--gallery-border-radius: 0.5rem;
	--gallery-image-margin: 0.25rem;
	position: fixed;
	top: 50%;
	left: 50%;
	background: var(--color-background);
	transform: translate(-50%, -50%);
	max-height: 95vh;
	z-index: 11;
	border: 1px solid var(--color-border);
	border-radius: var(--gallery-border-radius);
	width: calc(100% - 2rem);
}
.gallery_pop_up:not(.show) {
	display: none;
}
.gallery_pop_up .close,
.gallery_pop_up .previous_image,
.gallery_pop_up .next_image {
	position: absolute;
	width: 2rem;
	height: 2rem;
	padding: 0.5rem;
	border: 1px solid var(--color-border);
	border-radius: 0.25rem;
	line-height: 0;
	background: var(--color-background);
}
.gallery_pop_up .close svg,
.gallery_pop_up .previous_image svg,
.gallery_pop_up .next_image svg {
	width: 100%;
	height: 100%;
	object-fit: contain;
}
.gallery_pop_up .close {
	top: 1.25rem;
	right: 1.25rem;
}
.gallery_pop_up .previous_image,
.gallery_pop_up .next_image {
	top: 50%;
	transform: translateY(-50%);
}
.gallery_pop_up .previous_image {
	left: 1.25rem;
}
.gallery_pop_up .next_image {
	right: 1.25rem;
}
.image_wrapper {
	aspect-ratio: 1;
}
@media (orientation: landscape) {
	.image_wrapper {
		aspect-ratio: 16 / 10;
	}
}
.gallery_image {
	padding: var(--gallery-image-margin);
	margin: 0;
}
.gallery_image img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}
.gallery_image figcaption {
	padding: 0.5rem 1.2rem;
	text-align: center;
	font-size: 0.875rem;
}
.gallery_backdrop {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	width: 100%;
	height: 100%;
	background-color: var(--color-overlay-background);
}
.gallery_pop_up:not(.show) ~ .gallery_backdrop {
	display: none;
}

@media (width > 60rem) {
	.gallery_pop_up {
		max-width: 80%;
		height: 100%;
	}
	.gallery_image {
		--figcaption_height: 4rem;
		display: grid;
		grid-template-areas:
			"image"
			"caption";
		grid-template-rows: 1fr var(--figcaption_height);
		height: 95vh;
	}
	.image_wrapper {
		grid-area: image;
		max-width: 75vw;
		max-height: calc(95vh - var(--figcaption_height) - (var(--gallery-image-margin) * 2));
		margin: auto;
	}
	.gallery_image figcaption {
		grid-area: caption;
		background: var(--color-background-alt);
		padding: 0.5rem;
		margin: 0 calc(-1 * var(--gallery-image-margin)) calc(-1 * var(--gallery-image-margin))
			calc(-1 * var(--gallery-image-margin));
		border-bottom-left-radius: var(--gallery-border-radius);
		border-bottom-right-radius: var(--gallery-border-radius);
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
