@font-face {
	font-family: "Monosten";
	src: local("Monosten"), local("Monosten-Regular"), local("Monosten C"),
		url("/fonts/Monosten-Regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Roboto";
	src: local("Roboto"), local("Roboto-Regular"), url("/fonts/Roboto-Regular.woff2") format("woff2"),
		url("/fonts/Roboto-Regular.woff") format("woff"), url("/fonts/Roboto-Regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Roboto";
	src: local("Roboto Bold"), local("Roboto-Bold"), url("/fonts/Roboto-Bold.woff2") format("woff2"),
		url("/fonts/Roboto-Bold.woff") format("woff"), url("/fonts/Roboto-Bold.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Roboto";
	src: local("Roboto Medium"), local("Roboto-Medium"), url("/fonts/Roboto-Medium.woff2") format("woff2"),
		url("/fonts/Roboto-Medium.woff") format("woff"), url("/fonts/Roboto-Medium.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

:root {
	--header_height: 3.5rem;
	/* Global Vars */
	--docs_nav_height: 3.25rem;
	--docs_section_title_height: 4rem;
	--main_padding: 1.5rem;
	--scroll-margin: calc(3rem + var(--header_height));
	/* Fonts */
	--font-headings: "Monosten", system-ui;
	--font-body: "Roboto", system-ui;
	/* Colours */
	--color-brand: rgb(132 86 149);
	--color-brand-dark: rgb(39 29 102);
	--color-brand-faded-background: rgb(132 86 149 / 5%);
	--color-background: rgb(255 255 255);
	--color-background-alt: rgb(245 245 246);
	--color-text: rgb(44 44 44);
	--color-text-body: rgb(79 79 79);
	--color-text-faded: rgb(115 115 115);
	--color-background-reverse: rgb(0 0 0);
	--color-text-reverse: rgb(255 255 255);
	--color-border: rgb(215 215 215);
	--color-overlay-background: rgb(0 0 0 / 75%);
	--color-success: rgb(49 135 18);
	--color-error: rgb(219 57 57);
	/* Animations */
	--micro-animation: 0.4s ease-in-out;
}

@media (max-width: 40rem) {
	:root {
		--header_height: 3.5rem;
	}
}
@media (min-width: 40rem) and (max-width: 60rem), (max-width: 60rem) {
	:root {
		--header_height: 4.5rem;
	}
}

/**
 * ====================================
 * Resets
 * ====================================
 */

/* Box sizing rules */
*,
*::before,
*::after {
	box-sizing: border-box;
}

/* Set core body defaults */
body {
	min-height: 100vh;
	scroll-behavior: smooth;
	text-rendering: optimizeSpeed;
	margin: 0;
	line-height: 1.5;
	font-family: "Roboto", system-ui;
	color: var(--color-text-body);
	background: var(--color-background-alt);
}

.docs_content .content > p,
.docs_content .content > ul,
.docs_content .content > ol,
.docs_content .content > a {
	word-break: break-word;
}

.site_content {
	min-height: calc(100vh - 441px);
}

/* A elements that don't have a class get default styles */
a:not([class]) {
	text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
	max-width: 100%;
	display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
	font: inherit;
	color: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
	* {
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		transition-duration: 0.01ms !important;
		scroll-behavior: auto !important;
	}
}

/* Remove focus outlines for mouse users */
:focus:not(:focus-visible) {
	outline: none;
}

/* Default focus style */
:focus-visible:focus-visible {
	outline: 2px solid var(--color-brand);
	outline-offset: 2px;
}

/* Fieldset */
body:not(:-moz-handler-blocked) fieldset {
	display: table-cell;
}
fieldset {
	border: 0;
	padding: 0.01em 0 0 0;
	margin: 0;
	min-width: 0;
}
fieldset legend {
	padding: 0;
	display: table;
}

/**
 * ====================================
 * Common Utilities
 * ====================================
 */

.screen_reader_only {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	word-wrap: normal !important;
}
@media (max-width: 40rem) {
	.screen_reader_only_on_mobile {
		border: 0;
		clip: rect(1px, 1px, 1px, 1px);
		clip-path: inset(50%);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
		word-wrap: normal !important;
	}
}
@media (min-width: 40rem) and (max-width: 60rem) {
	.screen_reader_only_on_tablet {
		border: 0;
		clip: rect(1px, 1px, 1px, 1px);
		clip-path: inset(50%);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
		word-wrap: normal !important;
	}
}
@media (min-width: 60rem) {
	.screen_reader_only_on_desktop {
		border: 0;
		clip: rect(1px, 1px, 1px, 1px);
		clip-path: inset(50%);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
		word-wrap: normal !important;
	}
}


.loader {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 400px;
}
