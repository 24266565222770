.home_content > * {
	margin-left: auto;
	margin-right: auto;
	--container-width: var(--site-container-width);
}
.home_content > *:not(.wp-block-media-text) {
	padding-left: var(--main_padding);
	padding-right: var(--main_padding);
}
.home_content > *:not(.alignfull) {
	max-width: var(--container-width);
}
.home_content > .alignwide {
	--container-width: var(--site-container-wide-width);
}
.home_content > .alignfull {
	--container-width: 75rem;
}
.home_content .wp-block-media-text {
	background-color: var(--color-brand);
}
.home_content .wp-block-media-text.alignfull:not(.has-media-on-the-right) .wp-block-media-text__media {
	/* Make the edge of the image line up with the site container */
	margin-left: max(0px, calc((100vw - var(--container-width)) / 2));
}
.home_content .wp-block-media-text.alignfull.has-media-on-the-right .wp-block-media-text__media {
	/* Make the edge of the image line up with the site container */
	margin-right: max(0px, calc((100vw - var(--container-width)) / 2));
}
.home_content .wp-block-media-text.alignfull.has-full-width-img .wp-block-media-text__media {
	width: 100%;
	height: 100%;
	min-height: 250px;
	object-fit: contain;
	grid-column: 1;

}
.home_content .wp-block-media-text__content {
	padding: 2rem;
	position: relative;
}
.home_content .wp-block-media-text__content h2 {
	font-size: 2rem;
}
.home_content .wp-block-media-text__content p {
	font-size: 1.25rem;
}
.home_content .wp-block-media-text__content .button {
	font-size: 1.25rem;
	border-radius: 5rem;
	font-weight: bold;
}
.home_content .wp-block-media-text__content > *,
.home_content .wp-block-media-text__content .button_group > .button.outline {
	color: var(--color-text-reverse);
}
.home_content .wp-block-media-text .button:not(.outline) {
	background-color: var(--color-text-reverse);
	border-color: var(--color-text-reverse);
	color: var(--color-brand);
}
.home_content .wp-block-media-text .button.outline {
	border-color: var(--color-text-reverse);
}
.home_heading {
	text-align: center;
	color: var(--color-brand);
	font-weight: bold;
	font-size: 2rem;
}
.home_docs_section {
	padding: 1rem 0 2rem;
}
.home_docs_quick_links {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -1rem;
}
.docs_quick_link {
	display: inline-block;
	text-decoration: none;
	width: 50%;
	padding: 1rem;
}
.docs_quick_link_image {
	width: 100%;
	height: auto;
	aspect-ratio: 1;
	margin: 0;
	border-radius: 50%;
	overflow: hidden;
	background: var(--color-background);
}
.docs_quick_link_image img {
	width: 100%;
	height: 100%;
	object-fit: contain;
	padding: 10%;
}

.docs_quick_link_name {
	text-align: center;
}
.home_docs_buttons .button {
	width: max-content;
	margin: auto;
	font-size: 1.25rem;
	border-radius: 5rem;
	font-weight: bold;
}

.register_cta_list {
	padding: 0;
	list-style: none;
}

.register_cta_list li {
	display: flex;
	gap: 0.5rem;
	padding: 0.5rem;
	align-items: baseline;
	font-size: 1.125rem;
}
.register_cta_list li:not(:last-child) {
	border-bottom: 1px solid var(--color-text-reverse);
}
.register_cta_list li > svg {
	flex: 0 0 0.75rem;
}
.register_cta_button_group .button {
	border-radius: 5rem;
	width: max-content;
	max-width: 100%;
	font-weight: bold;
	font-size: 1.25rem;
	padding: 1rem 1.5rem;
}
.register_cta_button_group .secondary_login_text.secondary_login_text {
	font-size: 0.875rem;
}
.register_cta_button_group .secondary_login_text a {
	color: var(--color-text-reverse);
}
.newsletter,
.quick_favourites {
	padding: 2rem;
}
.home_docs_section .home_heading,
.newsletter .home_heading,
.quick_favourites .home_heading {
	margin-top: 0;
}
.newsletter p {
	font-size: 1.25rem;
	text-align: center;
	color: var(--color-text-faded);
}
.newsletter_signup_form {
	display: flex;
	align-items: stretch;
}
.newsletter_signup_email_wrapper {
	flex: 1;
	margin-bottom: 0 !important;
}
.newsletter_signup_email {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.newsletter_signup_submit {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.quick_favourites {
	max-width: var(--container-width);
}
.my_favourites:not(.no_favourites) {
	margin-bottom: 4rem;
}
.view_favourites {
	width: max-content;
	margin: auto;
	font-size: 1.25rem;
	border-radius: 5rem;
	font-weight: bold;
}

.successMail {
	margin-top: 20px;
	padding: 18px 15px 16px;
	border: 1px solid green;
	border-radius: 0.5rem;
	background-color: rgb(49 ,135, 18, 0.2);
	color: green;
	font-weight: 700;
}
.errorMail {
	margin-top: 20px;
	padding: 18px 15px 16px;
	border: 1px solid red;
	border-radius: 0.5rem;
	background-color: rgb(219,57,57, 0.2);
	color: red;
	font-weight: 700;
}

@media (40rem < width <= 60rem), (width > 60rem) {
	.home_docs_section {
		padding: 2rem 0 4rem;
	}
	.quick_favourites {
		padding: 4rem;
	}
	.home_docs_quick_links {
		margin: 0 -4%;
	}
	.docs_quick_link {
		width: 25%;
		padding: 4%;
	}
	.newsletter {
		padding: 8rem;
	}
	.home_content .wp-block-media-text {
		min-height: 37.5rem;
		position: relative;
	}
	.home_docs_search_wrapper {
		margin-top: 2rem;
	}
	.my_favourites {
		padding-top: 1rem;
	}
	.home_content .wp-block-media-text.alignfull.has-full-width-img .wp-block-media-text__media {
		position: absolute;
		top: 0;
		left: 0;
	}
}