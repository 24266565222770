@media print {
	@page {
		size: A4;
		margin: 5px;
	}

	.site_header,
	.docs_searchbar,
	.docs_section_title,
	.docs_nav,
	.site-footer,
	.docs_section:not(.print),
	.docs_subsection_content:not(.print),
	.section_actions,
	.section_actions + .docs_separator,
	.need_help_section {
		display: none;
	}

	.documentation {
		display: block;
	}

	a::after {
		content: " (" attr(href) ")";
	}

	a:not([href^="http://"]):not([href^="https://"])::after,
	a[href^="#"]::after {
		display: none;
	}

	.gallery .images {
		flex-wrap: wrap;
		gap: 1rem;
	}

	.gallery .images > a {
		flex: 0 0 calc(25% - (0.5rem * 3));
	}

	.content_header {
		position: static;
	}
	.docs_subsection_content {
		display: block;
	}

	.qrcode {
		position: absolute;
		top: 0;
		right: 0;
	}

	.footer_text {
		position: fixed;
		right: 0;
		bottom: 0;
		margin: 0px;
		padding: 0px;
	}
}
