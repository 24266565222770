.basic_page_content > * {
	margin-left: auto;
	margin-right: auto;
}
.basic_page_content > *:not(.wp-block-media-text):not(.wp-block-image) {
	padding-left: var(--main_padding);
	padding-right: var(--main_padding);
}
.basic_page_content > *:not(.alignwide):not(.alignfull) {
	--container-width: var(--site-container-width);
	max-width: var(--container-width);
}
.basic_page_content > .alignwide {
	--container-width: var(--site-container-wide-width);
}
