.login_form {
	margin: auto;
	width: 100%;
	max-width: 32rem;
	background-color: var(--color-background);
	border-radius: clamp(0rem, ((100vw - 0.25rem) - 100%) * 9999, 0.5rem);
	border: 1px solid var(--color-border);
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-areas:
		"username username"
		"password password"
		"forgot_pass forgot_pass"
		"remember_me submit";
	align-items: center;
	gap: 1.5rem;
}
.username_or_email_field_wrapper {
	grid-area: username;
}
.password_field_wrapper {
	grid-area: password;
}
.forgotten_password_link {
	grid-area: forgot_pass;
}
.remember_me_checkbox_wrapper {
	grid-area: remember_me;
	display: flex;
	align-items: center;
}
.login_submit {
	grid-area: submit;
}
.login_error_message {
	margin: 0;
	border: 1px solid var(--color-error);
	color: var(--color-error);
	border-radius: 0.5rem;
	padding: 0.5rem 1rem;
	grid-column: span 2;
}
.register_note {
	text-align: center;
}
@media (width <= 40rem) {
	.login_form {
		padding: 1.5rem;
	}
}
@media (40rem < width <= 60rem), (width > 60rem) {
	.login_form {
		padding: 2.5rem;
	}
}
