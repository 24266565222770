.my_account_header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 1rem 0;
}
.my_account_user_info p {
	margin: 0;
	display: flex;
	align-items: baseline;
	gap: 0.25rem;
}
.my_account_user_info .display_name {
	font-size: 1.2rem;
	color: var(--color-brand);
}
.my_account_user_info .username {
	font-size: 1rem;
	color: var(--color-text-faded);
}
.my_account_nav {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;

	border: 1px solid var(--color-border);
	overflow: hidden;
}
.my_account_nav_link {
	display: block;
	color: var(--color-text-faded);
	flex: 1 0 12rem;
	padding: 1rem;
	text-decoration: none;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;
	background: var(--color-background);
	border-bottom: 1px solid var(--color-border);
}

.my_account_nav_link > svg {
	width: 1rem;
	height: 1rem;
}
.my_account_nav_link.active {
	font-weight: bold;
	background-color: var(--color-brand);
	color: var(--color-text-reverse);
}
.my_account_content {
	background: var(--color-background);
	border: 1px solid var(--color-border);
	border-top: 0;
	margin-bottom: 4rem;
	padding: var(--main_padding);
}
@media (width <= 40rem) {
	.my_account_header {
		padding: 0 1rem;
	}
}
@media (40rem < width <= 60rem), (width > 60rem) {
	.my_account_container {
		padding: 1rem;
	}
	.my_account_nav {
		border-top-left-radius: 0.5rem;
		border-top-right-radius: 0.5rem;
	}
	.my_account_nav_link:not(:last-child) {
		border-right: 1px solid var(--color-border);
	}
	.my_account_content {
		border-bottom-left-radius: 0.5rem;
		border-bottom-right-radius: 0.5rem;
		padding: var(--main_padding);
	}
}
/* My account details */
.my_account_form {
	grid-gap: 1.5rem;
	background-color: var(--color-background);
	gap: 1.5rem;
	grid-template-columns: 1fr;
	width: 100%;
}
.my_account_form > h2 {
	margin: 0;
	color: var(--color-brand);
	margin-bottom: 25px;
}
.my_account_form > hr {
	margin: 0;
}

.field_wrapper {
	margin-bottom: 25px;
}

.newsletter_signup_checkbox_group_wrapper .field_label {
	font-weight: bold;
}
.newsletter_signup_checkbox_group_wrapper .field_label::after {
	font-weight: normal;
}
.button_group {
	display: flex;
	align-items: center;
	flex-wrap: wrap-reverse;
	margin-top: 25px;
}
.my_account_submit.my_account_submit {
	background-color: var(--color-success);
	border-color: var(--color-success);
	color: var(--color-text-reverse);
}
.my_account_cancel.my_account_cancel {
	border-color: var(--color-text-faded);
	color: var(--color-text-faded);
}
.my_account_submit,
.my_account_cancel {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;
}
.my_account_submit > svg,
.my_account_cancel > svg {
	width: 2rem;
	height: 2rem;
}
.my_account_delete.my_account_delete {
	margin-right: auto;
	border-color: var(--color-error);
	color: var(--color-error);
}
@media (width <= 40rem) {
	.my_account_delete {
		flex: 1 0 100%;
	}
	.my_account_cancel,
	.my_account_submit {
		flex: 1 0 auto;
	}
}
