.site-footer {
	background: var(--color-background-reverse);
	color: var(--color-text-reverse);
	padding: 4rem 1.25rem 3rem;
	font-size: 0.875rem;
}
.footer-title {
	font-family: "Roboto", system-ui;
	font-weight: bold;
	text-align: center;
	font-size: 2rem;
	margin: 0 0 2rem;
}
.footer_menu {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 1rem;
}
.footer_menu a {
	color: var(--color-text-reverse);
}
.copyright {
	text-align: center;
}
