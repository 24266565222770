.need_help_section {
	--need_help_button_width: 2.5rem;
	--need_help_inset: var(--main_padding);
}
.need_help_banner {
	position: fixed;
	bottom: 0;
	background: var(--color-background);
	border: 1px solid var(--color-border);
	width: 14rem;
	border-radius: 0.5rem;
	display: grid;
	grid-template-columns: 1fr 2.75rem;
	grid-template-areas: "title icon" "link icon";
	gap: 0 0.5rem;
	align-items: center;
	padding: 0.5rem;
	font-size: 0.875rem;
}
.need_help_banner .need_help_title {
	grid-area: title;
}
.need_help_banner .need_help_link {
	grid-area: link;
}
.need_help_banner .need_help_icon {
	grid-area: icon;
	color: var(--color-brand);
	width: 2.75rem;
	height: auto;
}
@media (max-width: 360px) {
	.need_help_banner {
		left: var(--need_help_inset);
		bottom: var(--need_help_inset);
	}
}
@media (min-width: 40rem) and (max-width: 60rem) {
	.need_help_button {
		position: fixed;
		bottom: var(--need_help_inset);
		right: var(--need_help_inset);
		width: var(--need_help_button_width);
		height: var(--need_help_button_width);
		border-radius: 5rem;
		border: 0;
		background: var(--color-brand);
		color: var(--color-text-reverse);
	}
	.need_help_banner {
		right: calc((var(--need_help_inset) * 2) + var(--need_help_button_width));
		bottom: var(--need_help_inset);
	}
	.need_help_banner:not(.show) {
		display: none;
	}
}
@media (min-width: 60rem) {
	.need_help_button {
		display: none;
	}
	.need_help_banner {
		right: var(--need_help_inset);
		bottom: var(--need_help_inset);
	}
}
