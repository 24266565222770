h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0.5em 0;
	line-height: 1.2;
	color: var(--color-text);
}

h4 {
	color: var(--color-brand);
}

p {
	margin: 1em 0;
}

a {
	color: var(--color-brand);
}

hr {
	border: none;
	border-bottom: 1px solid var(--color-border);
	margin: 1.5rem 0;
}

/* Buttons */
.button,
.wp-block-button a {
	padding: 0.5rem 1rem;
	background: var(--color-brand);
	border-radius: 0.5rem;
	border: 1px solid var(--color-brand);
	color: var(--color-text-reverse);
	cursor: pointer;
}
a.button,
.wp-block-button a {
	text-decoration: none;
	text-align: center;
	display: inline-block;
}
.button.outline,
.wp-block-button.is-style-outline a {
	background: none;
	color: var(--color-brand);
}
.button.has_icon {
	text-align: left;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 0.5rem;
}
.button.has_icon > svg {
	flex: 0 0 1em;
	width: 1em;
}

/* Button groups */
.wp-block-buttons,
.button_group {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -12px;
}
.wp-block-buttons .button,
.button_group .button {
	margin: 0 12px;
}

/* Table of contents and List of links block */
.table_of_contents,
.list_of_links {
	margin: 1em 0;
}
.table_of_contents a,
.list_of_links a {
	display: flex;
	padding: 0.5rem 0;
	gap: 0 0.5rem;
}
.table_of_contents a:not(:last-child),
.list_of_links a:not(:last-child) {
	border-bottom: 1px solid var(--color-border);
}
.table_of_contents a::before,
.list_of_links a::before {
	content: "";
	display: inline-block;
	flex: 0 0 1.5rem;
	height: 1.5rem;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 1rem;
}
.table_of_contents a::before {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23828282' viewBox='0 0 17 16'%3E%3Cpath d='M16.53 8.53c.3-.3.3-.77 0-1.06L11.76 2.7a.75.75 0 0 0-1.06 1.06L14.94 8l-4.24 4.24a.75.75 0 0 0 1.06 1.06l4.77-4.77ZM0 8.75h16v-1.5H0v1.5Z'/%3E%3C/svg%3E");
}
.list_of_links a::before {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3E%3Cpath stroke='%23828282' stroke-linecap='square' d='M2 9.09v4.25a1 1 0 0 0 1 1h9.38a1 1 0 0 0 1-1V9.09'/%3E%3Cpath fill='%23828282' d='M8.31 2a.5.5 0 1 0-1 0h1Zm-.85 10.21c.2.2.51.2.7 0l3.19-3.18a.5.5 0 0 0-.7-.7L7.8 11.14 5 8.32a.5.5 0 0 0-.71.71l3.18 3.18ZM7.31 2v9.86h1V2h-1Z'/%3E%3C/svg%3E");
}

/* Accordion block */
:not(.wp-block-custom.case_study) > .wp-block-custom.accordion {
	--accordion_padding: clamp(1rem, 1vw + 0.75rem, 1.5rem);
	margin-top: 1rem;
	margin-bottom: 1rem;
	border: 1px solid var(--color-border);
	border-radius: 1rem;
	background: var(--color-background);
	padding: var(--accordion_padding);
	max-width: calc(var(--container-width) - (var(--accordion_padding) * 2));
}
.wp-block-custom.accordion > summary {
	list-style: none;
	color: var(--color-brand);
}
.wp-block-custom.accordion > summary::-webkit-details-marker {
	display: none;
}
.wp-block-custom.accordion[open] .accordion_header_wrap svg {
	transform: rotate(45deg);
	transform-origin: center;
}

.accordion_header_wrap {
	display: flex;
	align-items: center;
	cursor: pointer;
	gap: 1rem;
}
.accordion_header_wrap .numbered_heading,
.accordion_header_wrap h2,
.accordion_header_wrap p {
	margin: 0;
	flex: 1;
}
.accordion_header_wrap h2 {
	color: var(--color-text-body);
	font-size: clamp(1.125rem, 1vw + 0.75rem, 1.5rem);
	font-weight: 500;
}
.accordion_header_wrap > hr,
.accordion_header_wrap .acf-block-preview > hr {
	display: none;
}
.wp-admin .accordion_header_wrap > .block-editor-inner-blocks {
	flex: 1;
}
.wp-admin .wp-block-acf-accordion-toggle {
	margin: 0;
}
.wp-admin .wp-block-acf-accordion-toggle .wp-block-acf-numbered-heading {
	margin: 0;
}
.accordion_header_wrap > svg {
	--accordion_icon_size: 1.25rem;
	flex-basis: var(--accordion_icon_size);
	height: var(--accordion_icon_size);
	transition: all var(--micro-animation);
}
.accordion-content > p:last-child {
	margin-bottom: 0;
}
/* Case Study block */
.wp-block-custom.case_study {
	border: 1px solid var(--color-border);
	border-radius: 1rem;
	background: var(--color-background-alt);
	padding: 1.5rem;
}

/* Resources table */
.resources_table {
	display: grid;
	grid-template-columns: repeat(auto-fit, 1fr);
	gap: 1rem;
}
.resources_table_row {
	display: grid;
	grid-template-columns: auto auto;
	gap: 0.5rem 1rem;
	background-color: #eee;
	padding: 0.5rem;
}
.resources_table_row dt {
	font-weight: 600;
}
.resources_table_row dd {
	margin: 0;
}
@media (40rem < width <= 60rem), (width > 60rem) {
	.resources_table {
		grid-template-columns: 1fr;
		gap: 0;
	}
	.resources_table_row {
		gap: 1rem;
		background-color: transparent;
		grid-template-columns: repeat(4, 1fr);
		margin: 0;
		padding: 0.5rem 0;
	}
	.resources_table_row:not(:first-of-type) {
		border-top: 1px solid var(--color-border);
	}
	.resources_table_row:not(:first-of-type) dt {
		border: 0;
		clip: rect(1px, 1px, 1px, 1px);
		clip-path: inset(50%);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
		word-wrap: normal !important;
	}
	.resources_table_row:first-of-type {
		grid-template-areas:
			"label_1 label_2 label_3 label_4"
			"value_1 value_2 value_3 value_4";
	}
	.resources_table_row:first-of-type dt:nth-of-type(1) {
		grid-area: label_1;
	}
	.resources_table_row:first-of-type dt:nth-of-type(2) {
		grid-area: label_2;
	}
	.resources_table_row:first-of-type dt:nth-of-type(3) {
		grid-area: label_3;
	}
	.resources_table_row:first-of-type dt:nth-of-type(4) {
		grid-area: label_4;
	}
	.resources_table_row:first-of-type dd:nth-of-type(1) {
		grid-area: value_1;
	}
	.resources_table_row:first-of-type dd:nth-of-type(2) {
		grid-area: value_2;
	}
	.resources_table_row:first-of-type dd:nth-of-type(3) {
		grid-area: value_3;
	}
	.resources_table_row:first-of-type dd:nth-of-type(4) {
		grid-area: value_4;
	}
}

/* Media text blocks */
.wp-block-media-text {
	direction: ltr;
	display: grid;
	grid-template-columns: 50% 1fr;
	grid-template-rows: auto;
}
.wp-block-media-text.has-media-on-the-right {
	grid-template-columns: 1fr 50%;
}

.wp-block-media-text.is-vertically-aligned-top .wp-block-media-text__content,
.wp-block-media-text.is-vertically-aligned-top .wp-block-media-text__media {
	align-self: start;
}
.wp-block-media-text .wp-block-media-text__content,
.wp-block-media-text .wp-block-media-text__media,
.wp-block-media-text.is-vertically-aligned-center .wp-block-media-text__content,
.wp-block-media-text.is-vertically-aligned-center .wp-block-media-text__media {
	align-self: center;
}

.wp-block-media-text.is-vertically-aligned-bottom .wp-block-media-text__content,
.wp-block-media-text.is-vertically-aligned-bottom .wp-block-media-text__media {
	align-self: end;
}

.wp-block-media-text .wp-block-media-text__media {
	grid-column: 1;
	grid-row: 1;
	margin: 0;
	position: relative;
	height: 100%;
	min-height: 250px;
}

.wp-block-media-text .wp-block-media-text__content {
	direction: ltr;
	grid-column: 2;
	grid-row: 1;
	word-break: break-word;
	padding: var(--main_padding);
}
.wp-block-media-text.alignfull:not(.has-media-on-the-right) .wp-block-media-text__content {
	/* Make the end of the text line up with the site container */
	padding-right: max(var(--main_padding), calc((100vw - var(--container-width)) / 2));
}
.wp-block-media-text.alignfull.has-media-on-the-right .wp-block-media-text__content {
	/* Make the end of the text line up with the site container */
	padding-left: max(var(--main_padding), calc((100vw - var(--container-width)) / 2));
}
.wp-block-media-text .wp-block-media-text__content :first-child {
	margin-top: 0;
}
.wp-block-media-text .wp-block-media-text__content :last-child {
	margin-bottom: 0;
}

.wp-block-media-text.has-media-on-the-right .wp-block-media-text__media {
	grid-column: 2;
	grid-row: 1;
}

.wp-block-media-text.has-media-on-the-right .wp-block-media-text__content {
	grid-column: 1;
	grid-row: 1;
}

.wp-block-media-text__media img,
.wp-block-media-text__media video {
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	object-fit: cover;
}

.wp-block-media-text.is-image-fill .wp-block-media-text__media {
	height: 100%;
	min-height: 250px;
	background-size: cover;
}

.wp-block-media-text.is-image-fill .wp-block-media-text__media > a {
	display: block;
	height: 100%;
}

.wp-block-media-text.is-image-fill .wp-block-media-text__media img {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}
@media (width <= 40rem) {
	.wp-block-media-text.is-stacked-on-mobile {
		grid-template-columns: 100% !important;
	}
	.wp-block-media-text.is-stacked-on-mobile .wp-block-media-text__media {
		grid-column: 1;
		grid-row: 1;
	}
	.wp-block-media-text.is-stacked-on-mobile .wp-block-media-text__content {
		grid-column: 1;
		grid-row: 2;
	}
}
@media (40rem < width <= 60rem), (width > 60rem) {
	.wp-block-media-text .wp-block-media-text__content {
		padding-inline: 4rem;
	}
}

/* Reset any default larger font sizes */
.editor-styles-wrapper .has-small-font-size,
.editor-styles-wrapper .has-normal-font-size,
.editor-styles-wrapper .has-medium-font-size,
.editor-styles-wrapper .has-large-font-size,
.editor-styles-wrapper .has-huge-font-size {
	font-size: initial !important;
}
