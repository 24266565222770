:root {
	--site-container-width: 52.5rem;
	--site-container-wide-width: 60rem;
}
.site_container {
	--container-width: var(--site-container-width);
	max-width: var(--container-width);
	margin-left: auto;
	margin-right: auto;
}

.site_container.padding {
	padding: var(--main_padding);
}
